import React from "react";

export interface CloudflareVideoProps {
  url: string;
}

const CloudflareVideo = (props: CloudflareVideoProps) => {
  return (
    <iframe
      style={{
        position: "absolute",
        height: "100%",
        width: "100vw",
        top: 0,
        left: 0,
        padding: 0,
        margin: 0,
        borderWidth: 0,
      }}
      key="video-stream"
      className="video-stream"
      title="video-stream"
      src={`https://iframe.videodelivery.net/${props.url}`}
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowFullScreen={true}
    ></iframe>
  );
};

export default CloudflareVideo;
