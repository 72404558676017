import React from "react";
import ImageSlide from "../ImageSlide";
import { useHistory, useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { useFullscreen, useKey } from "rooks";
import { BsCaretLeft, BsCaretRight } from "react-icons/bs";
import fullscreenIcon from "./fullscreen.svg";
import exitFullscreenIcon from "./exitFullscreen.svg";
import homeIcon from "./home.svg";
import "./Slide.css";

export type SlideState = (current: number, min: number, max: number) => number;

export interface SlidePage {
  type: "image" | "embed";
  source?: string;
  embed?: () => JSX.Element;
}

export interface SlideProps {
  min: number;
  max: number;
  pages: SlidePage[];
}

export interface ISlideParams {
  id: string;
}

const next: SlideState = (
  current: number,
  min: number,
  max: number
): number => {
  if (current === max) {
    return min;
  } else {
    return current + 1;
  }
};

const previous: SlideState = (
  current: number,
  min: number,
  max: number
): number => {
  if (current === min) {
    return max;
  } else {
    return current - 1;
  }
};

const Slide = (props: SlideProps) => {
  const fullscreen = useFullscreen();
  const history = useHistory();
  const { id } = useParams<ISlideParams>();
  const idNum = parseInt(id);
  const page = props.pages[idNum];

  const push = (transform: SlideState) => {
    history.push(`/slide/${transform(idNum, props.min, props.max)}`);
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData);
      if (eventData.dir === "Right") {
        push(previous);
      } else if (eventData.dir === "Left") {
        push(next);
      }
    },
  });

  useKey(["ArrowRight"], () => {
    push(next);
  });

  useKey(["ArrowLeft"], () => {
    push(previous);
  });

  if (!page) {
    // TODO: should go to an error page and error page should lead to home
    history.push(`/`);
    return <div>Not found</div>;
  }

  const navWidth = "120px";
  const navHeight = "100%";

  const fullscreenButton = fullscreen?.isFullscreen
    ? exitFullscreenIcon
    : fullscreenIcon;

  const content =
    page.type === "image" && page.source ? (
      <ImageSlide backgroundImage={page.source} />
    ) : page.embed ? (
      page.embed()
    ) : (
      <div>Not found</div>
    );

  return (
    <div
      style={{
        backgroundColor: "black",
        position: "absolute",
        top: 0,
        left: 0,
        margin: 0,
        height: "100%",
        width: "100vw",
      }}
      {...handlers}
    >
      {/* the slide content container. */}
      {content}

      {/* right navigation controller container. */}
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          margin: 0,
          height: navHeight,
          width: navWidth,
        }}
        onClick={() => push(next)}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "calc(50% - 20px)",
          right: "25px",
          margin: 0,
        }}
        className="icon-shadow-white"
        onClick={() => push(next)}
      >
        <BsCaretRight color="white" size="40" />
      </div>

      {/* left navigation controller container. */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          margin: 0,
          height: navHeight,
          width: navWidth,
        }}
        onClick={() => push(previous)}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "calc(50% - 20px)",
          left: "25px",
          margin: 0,
        }}
        className="icon-shadow-white"
        onClick={() => push(previous)}
      >
        <BsCaretLeft color="white" size="40" />
      </div>

      {/* upper-left home button */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          marginTop: "30px",
          marginLeft: "30px",
          height: "25px",
          width: "25px",
        }}
        onClick={() => {
          history.push("/");
        }}
      >
        <img className="icon-overlay" src={homeIcon} alt="Home" />
      </div>

      {/* upper-right full screen button */}
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          marginTop: "30px",
          marginRight: "30px",
          height: "25px",
          width: "25px",
        }}
        onClick={() => {
          if (fullscreen?.isFullscreen) {
            fullscreen?.exit();
          } else {
            fullscreen?.request();
          }
        }}
      >
        <img className="icon-overlay" src={fullscreenButton} alt="Fullscreen" />
      </div>
    </div>
  );
};

export default Slide;
