import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import CloudflareVideo from "./components/CloudflareVideo/CloudflareVideo";
import Slide, { SlidePage } from "./components/Slide/Slide";
import AboutPage from "./components/AboutPage";
import "./App.css";

function App() {
  const files = [];
  const fileStart = 1;
  const fileEnd = 105;
  const basePath = `/static/photos/`;
  for (let i = fileStart; i <= fileEnd; i++) {
    files.push(`${basePath}SET_${i.toString().padStart(5, "0")}.jpg`);
  }

  // files.forEach((img) => {
  //   new Image().src = img;
  // });

  const slide0: SlidePage = {
    type: "embed",
    embed: (): JSX.Element => (
      <div
        style={{
          width: "100vw",
          height: "100%",
          fontFamily: "cursive",
          color: "white",
          margin: 0,
          padding: 0,
          position: "absolute",
        }}
        className="slide-title"
      >
        <div
          style={{
            width: "100vw",
            top: "calc(50% - 4em)",
            position: "absolute",
            textAlign: "center",
          }}
        >
          <h1>Gil and Johannah</h1>
          <p>September 1, 2021</p>
        </div>
      </div>
    ),
  };

  const slide1: SlidePage = {
    type: "embed",
    embed: (): JSX.Element => (
      <CloudflareVideo url="70b03c20b801fd078354d392368f8dcd" />
    ),
  };

  const imageSlides = files.map<SlidePage>((f) => {
    return {
      type: "image",
      source: f,
    };
  });
  const slides = [slide0, slide1, ...imageSlides];

  return (
    <Router>
      <Switch>
        <Route path="/slide/:id">
          <Slide pages={slides} min={0} max={slides.length - 1} />
        </Route>
        <Route path="/about">
          <AboutPage />
        </Route>
        <Route path="*">
          <Redirect to="/slide/0" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
