import React from "react";

export interface IImageSlideProps {
  backgroundImage: string;
}

const backgroundColor: React.CSSProperties = {
  backgroundColor: "black",
  position: "absolute",
  top: 0,
  left: 0,
  margin: 0,
  height: "100%",
  width: "100vw",
};

const ImageSlide = (props: IImageSlideProps) => {
  const imageStyle: React.CSSProperties = {
    backgroundImage: `url(${props.backgroundImage})`,
    backgroundPosition: `center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `contain`,
    position: "absolute",
    top: 0,
    left: 0,
    margin: 0,
    height: "100%",
    width: "100vw",
  };

  const backgroundStyle: React.CSSProperties = {
    backgroundImage: `url(${props.backgroundImage})`,
    backgroundPosition: `center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `cover`,
    backgroundColor: "black",
    opacity: 0.5,
    position: "absolute",
    filter: "blur(8px)",
    WebkitFilter: "blur(8px)",
    top: 0,
    left: 0,
    margin: 0,
    height: "100%",
    width: "100vw",
  };

  return (
    <div>
      {/* Contains the background-color. Currently, black. */}
      <div style={backgroundColor}></div>

      {/* Contains the background-image. Currently, blurred version of the front image. */}
      <div style={backgroundStyle}></div>

      {/* Contains the foreground-image. Zoom as contained in the container div. */}
      <div style={imageStyle}></div>
    </div>
  );
};

export default ImageSlide;
